export default {
  namespaced: true,
  state: {
    sources: [],
    mediums: []
  },
  getters: {
    // Se crea un mapa de fuentes por nombre, para facilitar la búsqueda.
    sourceMapByName: state =>
      new Map(state.sources.map(source => [source.name.toLowerCase(), source])),
    // Se crea un mapa de medios por nombre, para facilitar la búsqueda.
    mediumMapByName: state =>
      new Map(state.mediums.map(medium => [medium.name.toLowerCase(), medium])),
    sourceByName: (_, getters) => id => getters.sourceMapByName.get(id),
    mediumByName: (_, getters) => id => getters.mediumMapByName.get(id)
  },
  mutations: {
    setSources(state, data) {
      state.sources = data;
    },
    setMediums(state, data) {
      state.mediums = data;
    }
  },
  actions: {}
};
