import { HEADERS_TABLE } from "../../../constants/tableHeaders";

const KEY = "leadsTableConfig";

/**
 * Funcion para cargar la configuracion de los headers
 * de la tabla de leads guardada en el localstorage
 */
function initTableConfig() {
  const config = JSON.parse(localStorage.getItem(KEY));
  return config ? config : HEADERS_TABLE.map(header => header.value);
}

export default {
  namespaced: true,
  state: {
    // almacena los keys de las columnas de la tabla de leads
    headers: initTableConfig()
  },
  getters: {
    /**
     * Funcion para obtener las columnas de la tabla de leads que se van a mostrar
     */
    headersTable: state =>
      HEADERS_TABLE.filter(header => state.headers.includes(header.value))
  },
  mutations: {
    SET_HEADERS(state, headers) {
      state.headers = headers;
      localStorage.setItem(KEY, JSON.stringify(headers));
    }
  },
  actions: {
    updateHeaders({ commit }, headers) {
      commit("SET_HEADERS", headers);
    }
  }
};
