export const HEADERS_TABLE = [
  {
    text: "Actions",
    class: "font-size-table-header",
    value: "actions",
    width: 25,
    sortable: false,
    sticky: true
  },
  {
    text: "Nombre",
    class: "font-size-table-header ",
    value: "contact_lead.name",
    width: 170,
    sticky: true
  },
  {
    text: "Teléfono",
    class: "font-size-table-header ",
    value: "contact_lead.phone"
  },
  {
    text: "Correo",
    class: "font-size-table-header ",
    value: "contact_lead.email"
  },
  {
    text: "Llegó por",
    class: "font-size-table-header ",
    value: "zones"
  },
  {
    text: "Presupuesto",
    class: "font-size-table-header ",
    value: "budget"
  },
  {
    text: "¿Cómo nos encontró?",
    class: "font-size-table-header ",
    value: "contact",
    width: 170,
    sortable: false
  },
  {
    text: "Asignado a",
    class: "font-size-table-header ",
    value: "internal_broker",
    sortable: false
  },
  {
    text: "Status",
    class: "font-size-table-header ",
    value: "phase"
  },
  {
    text: "Siguiente acción",
    class: "py-4 font-size-table-header",
    value: "postponed.date_at"
  },
  {
    text: "Tags",
    class: "py-4 font-size-table-header ",
    value: "multi_sales_opportunities",
    width: 180
  },
  {
    text: "Idioma",
    class: "py-4 font-size-table-header",
    value: "contact.language"
  }
];
