import Vue from "vue";
import Vuex from "vuex";
import contactBrokers from "./modules/contact-brokers-module";
import contacts from "./modules/contacts-module";
import leads from "./modules/leads/leads-module";
import logs from "./modules/logs-module";
import properties from "./modules/properties-module";
import amenities from "./modules/amenities-module";
import realEstateDevelopments from "./modules/real-estate-developments-module";
import realEstateGroup from "./modules/real-estate-group-module";
import reports from "./modules/reports-module";
import brex from "./modules/externalBroker-module";
import presentation from "./modules/presentation-module";

import { GetUserInfo, GetTrafficData } from "../api/user-api";
import traffics from "./modules/traffics-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    contactBrokers,
    contacts,
    leads,
    logs,
    properties,
    amenities,
    realEstateDevelopments,
    realEstateGroup,
    reports,
    brex,
    presentation,
    traffics
    // se setea en el root middleware, unicamente para el usuario root
    // de esta manera se evita que se cargue el modulo en otros roles
    // root
  },
  state: {
    token: localStorage.getItem("token"),
    role: localStorage.getItem("user_role"),
    name: localStorage.getItem("user_name"),
    contactId: localStorage.getItem("contact_id"),
    image: localStorage.getItem("user_image"),
    preferences: JSON.parse(localStorage.getItem("preferences")),
    realEstateGroupLogo: localStorage.getItem("real_estate_group_logo"),
    accessGroup: localStorage.getItem("ag"),
    trafficData: null,
    userData: {} //informacion del usuario
  },
  getters: {
    userName: state => {
      return state.name ? state.name : "";
    },
    userRole: state => {
      return state.role ? state.role : "";
    },
    userToken: state => {
      return state.token ? state.token : "";
    },
    realEstateGroupLogo: state => {
      if (
        state.realEstateGroupLogo === null ||
        state.realEstateGroupLogo === undefined ||
        state.realEstateGroupLogo === "undefined"
      ) {
        return "";
      } else {
        return state.realEstateGroupLogo ? state.realEstateGroupLogo : "";
      }
    },
    // devuelve si el usuario que inicio sesión que tienen acceso a modulos beta
    isExperimentalUser: state => {
      // por el momento solo los prueba inmobiliaria y de PPDC
      const experimentalAccessGroups = [
        "5e9a2df024535649bdc51f54",
        "5df8e0840ea2a06900beb7e3"
      ];
      return (
        experimentalAccessGroups.includes(state.accessGroup) ||
        experimentalAccessGroups.includes(localStorage.getItem("ag"))
      );
    }
  },
  mutations: {
    setUserInfo(state, userInfo) {
      // actualiza la información del usuario que está iniciando sesión
      if (userInfo && userInfo.token) state.token = userInfo.token;
      if (userInfo && userInfo.role) state.role = userInfo.role;
      if (userInfo && userInfo.name) state.name = userInfo.name;
      if (userInfo && userInfo.contactId) state.role = userInfo.contactId;
      if (userInfo && userInfo.userImage) state.image = userInfo.userImage;
      if (userInfo && userInfo.realEstateGroupLogo)
        state.realEstateGroupLogo = userInfo.realEstateGroupLogo;
    },
    setPreferences(state, data) {
      localStorage.setItem("preferences", JSON.stringify(data));
      state.preferences = data;
    },
    setTrafficData(state, value) {
      state.trafficData = value;
    },
    // cambia es estado de una variable global
    setUserData(state, value) {
      state.userData = value;
    }
  },
  actions: {
    // trae info de la api
    getUserInfobyId({ commit }) {
      try {
        return new Promise(resolve => {
          GetUserInfo()
            .then(res => {
              commit("setUserData", res.User);
              resolve(res);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getTrafficData({ commit }, filter) {
      try {
        return new Promise(resolve => {
          GetTrafficData(filter)
            .then(res => {
              commit("setTrafficData", res.TrafficRealEstate);
              resolve(res.TrafficRealEstate);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
});
