import store from "../store";
import request from "../helpers/request";

const API_URL = process.env.VUE_APP_API_ENDPOINT;

export function LogIn(email, password) {
  try {
    localStorage.removeItem("token");
    return new Promise((resolve, reject) => {
      fetch(API_URL + "/user/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      })
        .then(res => res.json())
        .then(async json => {
          if (json.Message === "Error: cuenta inactiva.")
            throw new Error(
              json.Message.split(":")[1]
                .trim()
                .toUpperCase()
            );
          if (json.Code !== 200) throw new Error(json.Message);
          let userToken = "";
          let userRole = "";
          if (json.Data && json.Data.Token) {
            userToken = json.Data.Token;
          } else
            throw new Error(
              "Por el momento no se puede ingresar al sistema. Code: 401-1"
            );
          if (json.Data && json.Data.Role) {
            userRole = json.Data.Role;
          } else
            throw new Error(
              "Por el momento no se puede ingresar al sistema. Code: 401-2"
            );

          setUserLoggedInfo(userToken, userRole);

          // obtener info del usuario logueado
          // como datos del contacto y logo de la inmobiliaria
          const userInfo = await UserInfo();
          const realEstateGroupLogo = getSafeNestedField(
            () => userInfo.User.real_estate_group[0].media.logo,
            ""
          );
          const userImage = getSafeNestedField(
            () => userInfo.User.contact[0].media.featured_image.src,
            ""
          );
          const contactId = getSafeNestedField(
            () => userInfo.User.contact_id,
            ""
          );
          // se guarda el access group para determinar en store/index si puede ver componentes experimentales
          // cambiar a que guarde en local_storage el valor si es usuario experimental o no, y asi evitar guardar el access group
          const accessGroup = getSafeNestedField(
            () => userInfo.User.contact[0].real_estate_group_id,
            ""
          );

          // poner en vuex los datos de la sesión
          let userSetVuex = {
            contactId,
            realEstateGroupLogo,
            accessGroup,
            userImage
          };
          store.commit("setUserInfo", userSetVuex);
          localStorage.setItem("user_name", userInfo.User.name);
          localStorage.setItem("real_estate_group_logo", realEstateGroupLogo);
          localStorage.setItem("user_image", userImage);
          localStorage.setItem("contact_id", contactId);
          // access group
          localStorage.setItem("ag", accessGroup);
          resolve({
            Role: userRole
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

function getUserLoggedInfo() {
  let token = store.getters.userToken;
  let role = store.getters.userRole;
  // let token2 = localStorage.getItem("token");
  // let role2 = localStorage.getItem("user_role");

  return {
    toke: token,
    role: role
  };
}

function setUserLoggedInfo(token, role) {
  localStorage.setItem("token", token);
  localStorage.setItem("user_role", role);

  // poner en vuex los datos de la sesión
  let userInfo = {
    token,
    role
  };
  store.commit("setUserInfo", userInfo);
  return true;
}

function removeUserLoggedInfo() {
  localStorage.removeItem("token");
  localStorage.removeItem("user_role");
  localStorage.removeItem("real_estate_group_logo");
  localStorage.removeItem("user_image");
  localStorage.removeItem("contact_id");
  localStorage.removeItem("ag");
}

/**
 * @todo Funcion para cerrar la sesión del usuario
 */
export function LogOut() {
  try {
    removeUserLoggedInfo();
    const data = {
      status: "200",
      message: "Se ha cerrado la sesión del usuario"
    };
    const promiseResult = Promise.resolve(data);
    return promiseResult;
  } catch (er) {
    const data = {
      status: "500",
      message: "Ocurrio un error al cerrar la sesión del usuario"
    };
    const promiseResult = Promise.reject(data);
    return promiseResult;
  }
}

export function IsLogged() {
  try {
    let { role } = getUserLoggedInfo();
    if (role) {
      return {
        Role: role
      };
    }
    return {};
  } catch (err) {
    return err;
  }
}

/**
 * @TODO Registrar usuario Por reestrucutrar
 * @param {Object} data
 */
export function CreateUser(user) {
  // se pueden pasar datos del contacto, y se registran de una vez
  let data = {
    name: user.name,
    password: user.password,
    role: user.role,
    contact_id: user.contact_id,
    email: user.email,
    phone: user.phone
  };
  return fetch(API_URL + "/user/create", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      data
    })
  })
    .then(res => res.json())
    .then(json => json.Data);
}

/**
 * @TODO Obtiene la información de un usuario
 * @param {Object} data
 */
export function UserInfo() {
  return new Promise((resolve, reject) => {
    request("/user/getUserById")
      .then(response => {
        let user = response.data.User;
        resolve({
          // Lead: parsedLeads
          User: user
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function GetUserInfo() {
  return new Promise((resolve, reject) => {
    request("/user/getUserById")
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function GetTrafficData(filters) {
  let body = filters === null ? {} : filters;
  return new Promise((resolve, reject) => {
    request("/traffic/listRealEstate", {
      filter: body
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function VerifyToken() {
  try {
    const { data } = await request("/user/verifyToken");
    if (data?.Token) {
      localStorage.setItem("token", data.Token);
      window.location.reload();
    }
    return;
  } catch (err) {
    return;
  }
}

// pasar a una librería
function getSafeNestedField(fn, defaultVal) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

export function searchUserName(name) {
  try {
    return new Promise((resolve, reject) => {
      request(`/user/search?search=${name}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
